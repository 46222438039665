import { jsPDF } from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';

import _ from 'lodash';

import { getSortedKeysBySortOrder } from '@/utils/report';

applyPlugin(jsPDF);

import '@/fonts/pdf/Oxygen_bold';
import '@/fonts/pdf/Oxygen_regular';
import '@/fonts/pdf/MPLUS1p_bold';
import '@/fonts/pdf/MPLUS1p_regular';

const MARGIN = 10;
const VERTICAL_MARGIN = 12;

const POWERED_BY_LOGO_SIZE = 5;
const POWERED_BY_LOGO_MARGIN = 5;

const FIRST_LINE_X = MARGIN;

const LINE_HEIGHT = 6;

const IMAGE_HEIGHT = 40;
const PLACEHOLDER_WIDTH = 70;

const TABLE_COLUMNS = [{dataKey: 'name'}, {dataKey: 'value'}, {dataKey: 'unit'}];

const ACCENT_COLOR = [159, 89, 182];

const ROUNDED_TRIANGLE_PATH = [
  [0 ,-2],
  [0,-0.75, 0,-1.5, 1,-1],
  [4 , 2],
  [1.5,0.75, 1.5,1.25, 0,2],
  [-4, 2],
  [-1,0.5, -1,-0.25, -1,-1],
  [0 ,-2]
];

function textAndDimension(doc, text, x, y, options){
  doc.text(text, x, y, options);
  return doc.getTextDimensions(text);
}

function booleanIcon(doc, value, x, y){ // eslint-disable-line no-unused-vars
  let currentFont = doc.getFont().fontName;

  doc.setFont('MPLUS1p');
  doc.text(value ? '\u2714' : '\u2716', x, y);
  doc.setFont(currentFont);
}

function mediaPlaceholder(doc, x, y, width, height, i18n, mimeType) {
  let fileType = 'file';
  if (mimeType != null) {
    if (mimeType.includes('image')) {
      fileType = 'image';
    } else if (mimeType.includes('video')) {
      fileType = 'video';
    }
  }
  
  //Draw a rounded rectangle as a placeholder thumbnail
  doc.setDrawColor(...ACCENT_COLOR);
  doc.setLineWidth(0.5);
  doc.roundedRect(x, y, width, height, 1, 1);
  //Draw the placeholder text in the center, with the subtitle, being below the center and the main text above the center
  let placeHolderText = i18n.$t(`report.view.no_preview.${ fileType }`) || ''; //Use a placeholder text according to the filetype
  doc.getTextDimensions(placeHolderText);
  doc.text(placeHolderText, x + (width / 2), y + (height / 2) - 1, {align: 'center', baseline: 'bottom'});
  //In the subtitle, refer to the attachments
  let placeHolderAttachmentText = i18n.$t('report.view.no_preview.see_attachments') || '';
  doc.getTextDimensions(placeHolderAttachmentText);
  doc.text(placeHolderAttachmentText, x + (width / 2), y + (height / 2) + 1, {align: 'center', baseline: 'top'});
}

function displayCategory(doc, category, titleFontWeight, titleIndent, titleTopMargin, nextY, i18n, indexValue = undefined, previousUnusedTitles = [], {accentTextColor, primaryTableColor, secondaryTableColor, titleTextColor}){
  let currentFontSize = doc.getFontSize();

  let titleCellDef;

  let tableData = [];

  let items = [];

  for (let itemName of getSortedKeysBySortOrder(category.items, true)){ //TODO Add sound indicator for sound files
    let item = category.items[itemName];
    let newTableEntry = {
      name: item.display_name,
      value: item.value,
      unit: item.unit
    };

    //Save a shallow copy of this item
    items.push({...item});

    tableData.push(newTableEntry);
  }

  if (category.display_name && category.display_name.length > 0){
    let titleRow = [[
      {
        content: category.display_name,
        colSpan: ((indexValue != null) ? 1 : TABLE_COLUMNS.length),
        styles: {
          fontStyle: titleFontWeight,
          cellPadding: {top: titleTopMargin, right: ((indexValue != null) ? 0 : 2), bottom: 2, left: titleIndent}
        }
      }
    ]];

    //Add index if it exists
    if (indexValue != null) {
      let indexText = `${indexValue}`;
      let indexTextDimensions = doc.getTextDimensions(indexText);
      titleRow[0].push(
        {
          content: indexText, //TODO Localize index string values!
          colSpan: TABLE_COLUMNS.length - 1,
          styles: {
            fontStyle: titleFontWeight,
            cellPadding: {top: titleTopMargin, right: 30 + 10 - indexTextDimensions.w, bottom: 2, left: 0}, //Right Margin is min size of last two columns
            halign: 'right',
            textColor: titleTextColor
          }
        }
      );
    }

    if (tableData.length == 0) {//If we don't have a table, return the current title to use in the next call
      //Return early with added titles. They get stacked on the next call with a valid table.
      return { 
        unusedTitles: previousUnusedTitles.concat(titleRow),
        nextY
      };
    } else {
      //Otherwise set title as the header of the table
      titleCellDef = previousUnusedTitles.concat(titleRow);
    }
    
  }

  if (tableData.length > 0){
    let currentTextColor = doc.getTextColor();
    doc.setTextColor('black')
    doc.autoTable({
      startY: nextY,
      body: tableData,
      head: titleCellDef,
      showHead: (titleCellDef != null) ? 'firstPage' :'never',
      headStyles: { fillColor: false, fontSize: currentFontSize, textColor: currentTextColor },
      styles: { textColor: 10, font: 'Oxygen' },
      bodyStyles: { fillColor: primaryTableColor },
      alternateRowStyles: { fillColor: secondaryTableColor },
      rowPageBreak: 'avoid',
      margin: { top: 0, right: 10, bottom: 0, left: 10 },
      columns: TABLE_COLUMNS,
      columnStyles: {
        value: { halign: 'right' },
        unit: { textColor: accentTextColor, fontStyle: 'bold', cellWidth: 30 }
      },
      didParseCell: function(data) {
        //Only calculate once per row! And only if it is part of the body!
        if (data.row.section === 'body' && data.column.index === 0){
          let item = items[data.row.index];

          let files = [];

          if (item.files != null || item.file != null) {
            //Stores indizes of the files as rows to determine how many files fit in a single row and when they need to be split to the next row
            item.filePrintIndizes = [{width: 0, indizes: []}];
      
            let tableWidth = doc.internal.pageSize.getWidth() - data.settings.margin.left - data.settings.margin.right;

            let maxCellHeight = doc.internal.pageSize.getHeight() - data.settings.margin.top - data.settings.margin.bottom - 1; //Prevent cells from being longer than one page and being broken up

            if (Array.isArray(item.files)) { //Multiple files
              files = item.files;
            } else if (item.file != null) {
              files = [item.file];
            }

            item.files = _.map(files, (file, fileIndex) => {
              let newFile = {
                mime: file.mime
              };
              let thumbnail;

              if (file.mime.includes('image')) {
                thumbnail = file.blobURL;
              } else if (file.thumbnail) {
                thumbnail = file.thumbnail;
              }

              
              let imageProps;
              try {
                if (thumbnail == null) throw new Error(`No valid thumbnail provided for type: ${file.mime}`);
                //Create image element for better loading and to prevent issues with incompatible formats and URL decoding
                newFile.imageElement = document.createElement('img');
                newFile.imageElement.crossOrigin = 'anonymous';
                newFile.imageElement.src = thumbnail;

                //Prepare image for display
                imageProps = doc.getImageProperties(newFile.imageElement);
              } catch (error) {
                console.error('Error analysing image properties for PDF', error);
                //On error set a placeholder image
                newFile.invalid = true;
                imageProps = { width: PLACEHOLDER_WIDTH, height: IMAGE_HEIGHT };
              }

              let resizeFactor =  IMAGE_HEIGHT / imageProps.height;

              newFile.printSize = {
                width: imageProps.width * resizeFactor,
                height: IMAGE_HEIGHT
              }

              let imageWidthWithPadding = data.cell.padding('horizontal') + newFile.printSize.width;

              let newRowWidth = item.filePrintIndizes[item.filePrintIndizes.length -1].width + imageWidthWithPadding;
              if (newRowWidth > tableWidth) {
                item.filePrintIndizes.push({width: 0, indizes: [
                  fileIndex
                ]});
              } else {
                item.filePrintIndizes[item.filePrintIndizes.length -1].indizes.push(fileIndex);
              }

              item.filePrintIndizes[item.filePrintIndizes.length -1].width += imageWidthWithPadding;

              return newFile;
            });

            data.cell.styles.minCellHeight = Math.min(
              LINE_HEIGHT + ((data.cell.padding('vertical') + IMAGE_HEIGHT) * item.filePrintIndizes.length),
              maxCellHeight
            );
          }
        }
      },
      didDrawCell: function(data) {
        if (data.row.section === 'body' && data.column.dataKey === 'unit'){
          let currentFontSize = doc.getFontSize();
          let hiddenImageCount = 0;

          let item = items[data.row.index];

          let maxCellY = doc.internal.pageSize.getHeight() - data.settings.margin.bottom; //Prevent images from being outside cells, if it already fills the whole page

          if (item != null && (item.files != null || item.file != null) && item.filePrintIndizes != null) {
            let files;
            if (Array.isArray(item.files)) { //Multiple files
              files = item.files;
            } else if (item.file != null) {
              files = [item.file];
            }

            for (let filePrintIndexRowIndex = 0; filePrintIndexRowIndex < item.filePrintIndizes.length; filePrintIndexRowIndex++){
              let currentX = doc.internal.pageSize.getWidth() - data.settings.margin.right;

              for (let filePrintIndex of item.filePrintIndizes[filePrintIndexRowIndex].indizes) {
                let file = files[filePrintIndex];

                let calculatedY = data.cell.y + LINE_HEIGHT + data.cell.padding('top') + ((data.cell.padding('vertical') + IMAGE_HEIGHT) * filePrintIndexRowIndex);

                //If the image fits in the cell, add it
                if ((calculatedY + IMAGE_HEIGHT + data.cell.padding('bottom')) <= maxCellY){
                  let drawnWidth, drawnHeight;
                  currentX -= data.cell.padding('right')
                  //Only show loaded and valid files!
                  if (file.imageElement != null && !(file.invalid)) {
                    currentX -= file.printSize.width;
                    drawnWidth = file.printSize.width;
                    drawnHeight = file.printSize.height;

                    //Try to add the image or skip it
                    try {
                      doc.addImage(
                        file.imageElement, 
                        currentX,
                        calculatedY, 
                        file.printSize.width, 
                        file.printSize.height, 
                        '', 'FAST');
                    } catch (error) {
                      console.error('Error adding image to PDF', error);
                      //Show a placeholder for invalid files
                      mediaPlaceholder(doc, currentX, calculatedY, file.printSize.width, file.printSize.height, i18n, file.mime);
                    }
                  } else {
                    currentX -= PLACEHOLDER_WIDTH;
                    drawnWidth = PLACEHOLDER_WIDTH;
                    drawnHeight = IMAGE_HEIGHT;

                    //Otherwise show a placeholder for invalid files
                    mediaPlaceholder(doc, currentX, calculatedY, PLACEHOLDER_WIDTH, IMAGE_HEIGHT, i18n, file.mime);
                  }

                  //Draw a video indicator, if it is a video file
                  if (file.mime != null && file.mime.includes('video')) {
                    doc.setDrawColor(255, 255, 255);
                    doc.setLineWidth(0.5);
                    doc.lines(ROUNDED_TRIANGLE_PATH, currentX + 2, calculatedY + 4, [0.75, 0.75], 'FD', true);
                  }
                  
                  //If it is multiple files, add a multiple files indicator with their index
                  if (files.length > 1) {
                    let indicatorCenter = { x: (currentX + drawnWidth - 4), y: (calculatedY + drawnHeight - 4)};
                    //Set font and color
                    doc.setFontSize(8);
                    doc.setFillColor(255, 255, 255);
                    //Draw
                    doc.circle(indicatorCenter.x, indicatorCenter.y, 3, 'F');
                    doc.text(_.toString((filePrintIndex + 1)), indicatorCenter.x, indicatorCenter.y, {align: 'center', baseline: 'middle'});
                    //Reset font and color
                    doc.setFontSize(currentFontSize);
                    doc.setFillColor(...ACCENT_COLOR);
                  }
                  
                  currentX -= data.cell.padding('left');

                } else { //Show an indicator of how many images are not being displayed
                  hiddenImageCount++;
                }
              }
            }
          }

          if (hiddenImageCount > 0){
            let text;
            if (hiddenImageCount === 1){
              text = `${hiddenImageCount} ${i18n.$t('report.view.image_hidden')}`;
            } else {
              text = `${hiddenImageCount} ${i18n.$t('report.view.images_hidden')}`;
            }
            let textDims = doc.getTextDimensions(text);
            
            doc.text(text, 
              (doc.internal.pageSize.getWidth() - data.settings.margin.right - textDims.w - data.cell.padding('right')), 
              data.cell.getTextPos().y + textDims.h
            );
          }
        }
      },
      willDrawCell: function(data) {
        //Only calculate once per row!
        if (data.column.index === 0){
          //Get padding from first cell
          let firstCellPadding = _.get(data.cell, ['styles', 'cellPadding', 'top'], null) || _.get(data.cell, ['styles', 'cellPadding'], 0);
          let startOfFirstLine = data.cursor.y + firstCellPadding;

          //If it is a head cell, check that it doesn't get out of bounds including the cells up to (including) the first cell of the body
          if (data.section === 'head') {
            //Calculate the total height of the head from the current head row index
            let headHeight = _.sumBy(_.filter(data.table.head, (headRow) => headRow.index >= data.row.index), 'height');
            let firstBodyHeight = _.get(data.table.body, ['0', 'height'], 0);
            //Add height of the first body cell and the total height of the head to the current position to determine if the first body cell would be out of bounds 
            let endOfFirstBodyCell = data.cursor.y + headHeight + firstBodyHeight;

            //If it is out of bounds including the margin, skip to the next page
            if (endOfFirstBodyCell > (doc.internal.pageSize.getHeight() - VERTICAL_MARGIN)) {
              doc.addPage();
              //Set current cell and next cursor to be one down
              data.cell.y = data.cursor.y = VERTICAL_MARGIN - firstCellPadding;
            }
          } else { //Otherwise, if it is not a head cell, check if the cell itself would be out of bounds
            //If the whole first cell does not fit into the page including the margin, skip to the next page
            let endOfFirstCell = data.cursor.y + data.cell.height;
            if (endOfFirstCell > (doc.internal.pageSize.getHeight() - VERTICAL_MARGIN)) {
              doc.addPage();
              //Set current cell and next cursor to be one down
              data.cell.y = data.cursor.y = VERTICAL_MARGIN - firstCellPadding;
            }
          }
          
          if (startOfFirstLine < VERTICAL_MARGIN) {
            //Set current cell and next cursor to be one down
            data.cell.y = data.cursor.y = VERTICAL_MARGIN - firstCellPadding;
          }
        }
      }
    });
    doc.setTextColor(currentTextColor);
    nextY = doc.lastAutoTable.finalY;
  }

  return { nextY }; // If we return here, a table was drawn. All previous titles are discarded
}

export async function generateReportsPDF(doc, i18n, qrInstance, report, colors = {}) {
  let styleProperties = getComputedStyle(document.documentElement);
  let { headerColor = styleProperties.getPropertyValue('--ion-color-primary-tint') || '#B9EDC7', titleTextColor = styleProperties.getPropertyValue('--ion-color-primary-text') || '#4D9961', accentTextColor = styleProperties.getPropertyValue('--ion-color-tertiary-text') || '#918ED4', primaryTableColor = styleProperties.getPropertyValue('--ion-color-primary-tint') || '#B9EDC7', secondaryTableColor = styleProperties.getPropertyValue('--ion-color-primary-tint-light') || '#d7eedd' } = colors;
  doc.setFillColor(headerColor);
  doc.rect(0, 0, doc.internal.pageSize.getWidth(), 30, 'F');
  doc.setFont('Oxygen');
  doc.setTextColor(80);
  doc.setDrawColor(80);
  doc.setFontSize(12);
  let titleStartX = FIRST_LINE_X;
  //TODO Custom logo!
  /*doc.addImage('/assets/icon/favicon.png', 10, 5, 15, 15);
  doc.text('anirec.de', 8.75, 26);
  doc.link(0, 0, 30, 30, {url: 'https://anirec.de'});
  //TODO If it is drawn, set starting X accordingly
  titleStartX += 25;
  */

  //Add a powered by text in the upper right corner with the anirec logo
  let anirecLogoPosition = {
    x: doc.internal.pageSize.getWidth() - POWERED_BY_LOGO_MARGIN - POWERED_BY_LOGO_SIZE,
    y: POWERED_BY_LOGO_MARGIN,
    leftMargin: 2
  };
  doc.setTextColor(titleTextColor);
  doc.setFontSize(10);
  let poweredByText = `${i18n.$t('report.powered_by')} anirec.de`;
  let poweredByTextDimension = doc.getTextDimensions(poweredByText);
  let textBoundaries = {
    x: (anirecLogoPosition.x - anirecLogoPosition.leftMargin - poweredByTextDimension.w),
    y: anirecLogoPosition.y,
    w: poweredByTextDimension.w + anirecLogoPosition.leftMargin + POWERED_BY_LOGO_SIZE,
    h: POWERED_BY_LOGO_SIZE
  };

  let boundaryRectMargin = 2;
  doc.setFillColor(255, 255, 255);
  doc.roundedRect((textBoundaries.x - boundaryRectMargin), (textBoundaries.y - boundaryRectMargin), (textBoundaries.w + (boundaryRectMargin * 2)), (textBoundaries.h + (boundaryRectMargin * 2)), 1, 1, 'F');
  doc.text(poweredByText, (anirecLogoPosition.x - anirecLogoPosition.leftMargin), (POWERED_BY_LOGO_MARGIN + (POWERED_BY_LOGO_SIZE / 2) - (poweredByTextDimension.h / 2)), { align: 'right', baseline: 'top' });
  doc.addImage('/assets/icon/logo.png', anirecLogoPosition.x, POWERED_BY_LOGO_MARGIN, POWERED_BY_LOGO_SIZE, POWERED_BY_LOGO_SIZE);
  let linkMargin = 2;
  doc.link((textBoundaries.x - linkMargin), (textBoundaries.y - linkMargin), (textBoundaries.w + (linkMargin * 2)), (textBoundaries.h + (linkMargin * 2)), {url: 'https://anirec.de'});

  doc.setTextColor(80);
  doc.setFontSize(18);
  doc.text(`${report.title}`, titleStartX, 13); //TODO Make normal texts break line or get cutoff! splitTextToSize?
  doc.setFontSize(16);
  doc.setTextColor(100);
  let timeTextDimension = textAndDimension(doc, `${report.time}`, titleStartX, 23);
  doc.setTextColor(80);
  if (report.control_examination === true) {
    doc.text(`| ${i18n.$t('report.control_examination')}`, titleStartX + timeTextDimension.w + 1, 23);
  }

  if (report.id != null) {
    let qrData = qrInstance.getQRData('report', report, report.id);

    let qrCodeInstance = qrInstance.generateQR(qrData, { color: true, svg: false, margin: 10 }, 'primary');

    let { imageBlob, extension } = await qrCodeInstance.export(false);

    let qrCodeImage = await imageBlob.arrayBuffer();

    doc.addImage(new Uint8Array(qrCodeImage), extension.toUpperCase(), doc.internal.pageSize.getWidth() - 30 - MARGIN, 18, 30, 30);
    doc.link(doc.internal.pageSize.getWidth() - 30 - MARGIN, 18, 30, 30, {url: qrData.fullUrl});
  }

  doc.setFontSize(12);

  //TODO Make those metadata a table and add more information about the animal
  
  let textSizes = [
    textAndDimension(doc, `${i18n.$t('report.associated_horse')}:`, FIRST_LINE_X, 38).w,
    textAndDimension(doc, `${i18n.$t('report.location.name')}:`, FIRST_LINE_X, 46).w
  ]

  let alignedX = Math.max(...textSizes) + FIRST_LINE_X + 5;

  doc.text(report.animal, alignedX, 38);
  doc.text(report.location, alignedX, 46);

  doc.setLineWidth(0.5);
  doc.setLineCap('round');
  doc.line(FIRST_LINE_X, 51, doc.internal.pageSize.getWidth() - MARGIN, 51);

  let nextY = 55;
  let unusedTitles = [[
    {
      content: report.title,
      colSpan: TABLE_COLUMNS.length,
      styles: {
        fontStyle: 'bold',
        cellPadding: {top: 5, right: 2, bottom: 0, left: 0},
        textColor: titleTextColor
      }
    },
  ]];

  for (let categoryName of getSortedKeysBySortOrder(report.fields, true)) {
    let category = report.fields[categoryName];
    ({ nextY, unusedTitles } = displayCategory(doc, category, 'bold', 4, 5, nextY, i18n, undefined, unusedTitles, {accentTextColor, primaryTableColor, secondaryTableColor, titleTextColor}));

    if (category.sub_categories){
      for (let subCategoryName of getSortedKeysBySortOrder(category.sub_categories, true)){
        let subCategory = category.sub_categories[subCategoryName];
        for (let indexValue of (subCategory.used_index_values || [undefined])) {
          let mappedSubcategory = {
            ...subCategory,
            //Map items to have indexed ones only use their values
            items: _.compact(_.map(subCategory.items, (item) => {
              let newItem = {
                ..._.omit(item, 'indexed_values'),
                ..._.pick(_.get(item, ['indexed_values', indexValue], {}), ['value', 'file', 'files']) //Try to get the content (value or files) of the indexed one and apply it to the original object
              }
              if (newItem.value == null && newItem.file == null && newItem.files == null) return undefined; //Only take valid items, that have value or files set
              return newItem;
            }))
          };
          ({ nextY, unusedTitles } = displayCategory(doc, mappedSubcategory, 'normal', 8, 2, nextY, i18n, indexValue, unusedTitles, {accentTextColor, primaryTableColor, secondaryTableColor, titleTextColor}));
        }
      }
    }
  }
}

function addPageMetadataInformation(doc, i18n, metadataInformation) {
  let sortedMetadata = _.sortBy(metadataInformation, 'startPage');
  let pageCount = doc.internal.getNumberOfPages();
  let currentMetadataIndex = 0;

  doc.setFontSize(10);
  for(let page = 0; page < pageCount; page++) { 
    doc.setPage(page + 1); //Page indexing starts at 1!
    
    let report = sortedMetadata[currentMetadataIndex];

    //If we are finished with the current metadata, move to the next one. Check if the current page is out of the current range, until we are in the correct range
    while (report != null && page >= (report.startPage + report.pageCount)) {
      currentMetadataIndex++;
      //Set to null if out of bounds to prevent error
      report = (currentMetadataIndex < sortedMetadata.length) ? sortedMetadata[currentMetadataIndex] : null;
    }

    //If the current report metadata is valid, add it
    if (report != null) {
      let currentPage = doc.internal.getCurrentPageInfo().pageNumber;
      let text = `${report.animal || ''} | ${report.title || ''} | ${report.time || ''} | ${i18n.$t('default_interaction.page')} ${currentPage} / ${pageCount}`;

      let textWidth = doc.getTextDimensions(text).w;
      doc.text(text, doc.internal.pageSize.getWidth() - textWidth - MARGIN, doc.internal.pageSize.getHeight() - 6);
    }
  }
}

export default function PdfReport(i18n, qrInstance, report) {
  this.doc = new jsPDF({ putOnlyUsedFonts: true }); 
  this.reportMetadata = [];

  this.attachReport = function(attachedReport, firstPage = false){
    let startPage = 0;
    if (!firstPage) {
      startPage = this.doc.internal.getNumberOfPages();
      //TODO Check here for report connection and don't change pages, if it didn't change. Should be already sorted when given by the share modal. Keep the last Y of the previous report!
      this.doc.addPage();
    }

    this.promise = generateReportsPDF(this.doc, i18n, qrInstance, attachedReport).then(() => {
      //Push metadata for table of contents and footer for page information
      this.reportMetadata.push({ //TODO If multiple reports per page this needs to be adjusted!
        ..._.pick(attachedReport, ['animal', 'title', 'time']),
        startPage: startPage,
        pageCount: ((this.doc.internal.getNumberOfPages()) - startPage)
      });
    });
  }

  this.addTableOfContents = function() { //TODO Add Table of Contents with multiple reports //TODO Add a title page with address, free form text, etc! Maybe give option to choose a free form field that goes to the top?

  }

  this.export = function(){
    addPageMetadataInformation(this.doc, i18n, this.reportMetadata);
    return this.doc.output('blob');
  }

  this.attachReport(report, true);
}