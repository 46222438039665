<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="isConnection ? i18n.$t('report.view.title_connection') : i18n.$t('report.view.title')" />
    </ion-header>
    <ion-content>
      <!--TODO Maybe add green pencil icon to reports that are being updated, also in upload overview, and also maybe on already updated ones -->
      <!--TODO Was passiert mit Bericht, Status und Dateien, sobald der Bericht hochgeladen ist, während er offen ist? -->

      <template v-if="!loading">
        <!-- Header for fixed inputs in all reports -->
        <div class="header-container">
          <ion-card class="header-card metadata">
            <ion-card-header>
              <ion-card-title>Berichtsinformationen</ion-card-title> <!-- TODO Add good title for metadata and notes. And make it smaller on iOS!! Like in Analysis! Or in EditModal! -->
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <!-- Indicator with link, if single report is part of a connection -->
                <ion-item v-if="isSingleConnectedReport" lines="none">
                  <ion-button v-for="connectionId in getSingleReportConnection" :key="connectionId" class="connection-indicator" @click="openReportConnection(connectionId)">
                    <ion-icon :icon="documentTextOutline"></ion-icon>
                    <ion-label>{{ i18n.$t('report.view.single_report_connected') }}</ion-label>
                    <ion-icon :icon="chevronForward"></ion-icon>
                  </ion-button>
                </ion-item>

                <ViewFormItem
                  lines="full"
                  :display_name="i18n.$t('report.create.examination_time')"
                  type="datetime"
                  :value="getReportProperty(REPORT_PROPERTY_MAPPINGS['timestamp'])"
                  :icon="faCalendarDay"
                  iconIsFontAwesome >
                </ViewFormItem>
                <ion-item lines="full" class="animal-select" detail button @click="openAnimal(getReportProperty(REPORT_PROPERTY_MAPPINGS['animal']))">
                  <ion-label position="stacked">{{ i18n.$t('report.associated_horse') }}</ion-label>
                  <AnimalSelect
                    :selectedAnimalId="getPersonalInfoId(getReportProperty(REPORT_PROPERTY_MAPPINGS['animal']))"
                    :availableAnimals="availableHorses"
                    showAvatar
                    showAdditionalInfo
                    viewOnly >
                  </AnimalSelect>
                </ion-item>

                <ViewFormItem
                  :lines="(analysesStatistics.totalCount > 0) ? 'full' : 'none'"
                  :display_name="i18n.$t('report.location.name')"
                  type="text"
                  :value="i18n.$t(`report.location.${getReportProperty(REPORT_PROPERTY_MAPPINGS['location'])}`)"
                  :icon="faHouseMedicalFlag"
                  iconIsFontAwesome >
                </ViewFormItem>
                <ViewFormItem
                  v-if="analysesStatistics.totalCount > 0"
                  lines="none"
                  :display_name="i18n.$t('tracking.analyses.title')"
                  type="text"
                  :value="generateAnalysesStatisticsText(analysesStatistics)" >
                  <template v-slot:icon>
                    <AnimatedLogo name="cycle" stopPattern="cross" class="logo" duration="3000" :enabled="analysesStatistics.uncompleted > 0"></AnimatedLogo>
                  </template>
                </ViewFormItem>
              </ion-list>
            </ion-card-content>
          </ion-card>

          <!--<ion-card class="header-card additional">
            <ion-card-header>
              <ion-card-title>Notizen</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ViewFormItem
                lines="full"
                :display_name="i18n.$t('report.voice_note')"
                class="voice-note-input"
                type="audio"
                :value="getReportMetadata('voice_note')"
                :icon="faVoicemail"
                iconIsFontAwesome >
              </ViewFormItem>
              <ViewFormItem
                lines="none"
                :display_name="i18n.$t('report.text_note')"
                type="text"
                :value="getReportMetadata('text_note')"
                :icon="faClipboardList"
                iconIsFontAwesome >
              </ViewFormItem>
            </ion-card-content>
          </ion-card>-->
        </div>

        <!-- Report Fields start here -->
        <ion-card class="protocol-selection-header">
          <ion-card-header v-if="isConnection">
            <ion-card-subtitle>{{ i18n.$t('report.view.protocol_selection') }}</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <div class="protocol-selection-overflow-bounds">
          <ion-card class="protocol-selection-container">
            <LongTabBar
              class="protocol-selection"
              :title="i18n.$t('report.view.protocol_selection')"
              :selectableOptions="availableReportsWithTypes" 
              v-model:selected="currentVisibleReport"
              :emptyDisplayFallback="i18n.$t('report.view.no_protocol_name')">
            </LongTabBar> <!-- TODO Use the button in the end to also show an option for selecting "Add protocol" for a popup with more protocols, if enabled. Disable here! -->
          </ion-card>
        </div>
        <ion-card class="protocol-content-container">
          <ion-card-content class="protocol-content">
            <div 
              v-for="(report, reportIndex) in loadedReportsFieldsAndTypes"
              :key="reportIndex"
              :class="[(report.id != currentVisibleReport) ? 'protocol-hidden' : undefined]">
              <ion-list class="protocol-content-list">
                <!-- Uploading indicator -->
                <ion-item lines="full" v-if="isReportBeingUploaded(report) && getUploadStatusForReport(report) != null" class="upload-status">
                  <ion-label :style="'color: var(' + getUploadStatusForReport(report).design.color + ');'">
                    {{ i18n.$t("upload-status." + getUploadStatusForReport(report).design.text) }}
                  </ion-label>
                  <ion-button 
                    shape="round"
                    fill="clear"
                    class="upload-progress-button"
                    :color="getUploadStatusForReport(report).shortColor"
                    @click.stop="showUploadStatus()"> 
                    <CircularProgress
                      class="upload-progress"
                      :style="'--color: var(' + getUploadStatusForReport(report).design.color + ');'"
                      :progress="(getUploadStatusForReport(report).status.progress >= 0) ? getUploadStatusForReport(report).status.progress : 0">
                      <ion-icon :icon="getUploadStatusForReport(report).design.icon"></ion-icon>
                    </CircularProgress>
                  </ion-button> 
                </ion-item>

                <!-- Indicator for history of versions -->
                <ion-item v-if="report.id != null && hasRelatedReports(report)" class="version-history-button" lines="full" button detail @click="showRelatedReportList(report)">
                  <ion-icon color="primary" slot="start" :icon="history"></ion-icon>
                  <ion-label color="primary">{{ i18n.$t('report.view.related-reports.documents-title') }}</ion-label>
                </ion-item>

                <!-- List of analyses -->
                <CollapsibleList 
                  class="analyses-header"
                  v-if="analysesStatusByReport[report.id] != null && analysesStatusByReport[report.id].statistics != null && analysesStatusByReport[report.id].statistics.totalCount > 0" >
                  <template v-slot:label>
                    <ion-label class="analyses-title">
                      {{ i18n.$t('tracking.analyses.in_report') }} &mdash;
                      {{ generateAnalysesStatisticsText(analysesStatusByReport[report.id].statistics) }}
                    </ion-label>
                  </template>
                  <template v-slot:end>
                    <AnimatedLogo name="cycle" stopPattern="cross" class="logo" duration="3000" :enabled="analysesStatusByReport[report.id].statistics.uncompleted > 0"></AnimatedLogo>
                  </template>
                  <template v-for="(analysisItem, analysisIndex) in getAllAnalysesForReport(report.id)" :key="analysisIndex">
                    <ViewAnalysisItem
                      class="form-item analysis-item"
                      lines="full"
                      :index="analysisIndex"
                      :analysis="analysisItem"
                      :analysedData="getAnalysesOriginalData(report.fields, analysisItem)"
                      @scrollToItem="(itemHierarchyFieldKey) => scrollToItem(report.id, ...itemHierarchyFieldKey)">
                    </ViewAnalysisItem>
                  </template>
                </CollapsibleList>
                
                <CollapsibleList v-for="(categoryName, categoryNameIndex) in getSortedKeysBySortOrder(report.fields)" 
                  class="category-header"
                  :key="categoryNameIndex" 
                  :open="categoryName == UNCATEGORIZED_CATEGORY"
                  :showHeader="categoryName !== UNCATEGORIZED_CATEGORY"
                  :title="getLocalizedCategoryName(categoryName)" >
                  <template v-for="(itemName, itemNameIndex) in getSortedKeysBySortOrder(report.fields[categoryName].items)" :key="itemNameIndex">
                    <ViewFormItem
                      class="form-item"
                      lines="full"
                      :display_name="getLocalizedItemName(categoryName, itemName)"
                      :type="getLocalizedItem(report.fields, categoryName, itemName).type"
                      :value="getLocalizedItem(report.fields, categoryName, itemName).displayValue"
                      :unit="getLocalizedItem(report.fields, categoryName, itemName).unit"
                      :id="createUniqueItemID(report.id, categoryName, itemName)" >
                    </ViewFormItem>
                  </template>
                  <div v-for="(subCategoryName, subCategoryNameIndex) in getSortedKeysBySortOrder(report.fields[categoryName].sub_categories)" :key="subCategoryNameIndex">
                    <template v-for="indexValue in (report.fields[categoryName].sub_categories[subCategoryName].used_index_values || [undefined])" :key="indexValue"> <!-- Array with undefined as fallback to show at least one -->
                      <ion-item-divider class="subcategory-divider">
                        <ion-label>
                          {{ getLocalizedCategoryName(subCategoryName) }}
                          <span v-if="indexValue != null" class="subcategory-index">{{ indexValue }}</span>
                        </ion-label>
                      </ion-item-divider>
                      <template v-for="(itemName, itemNameIndex) in getSortedKeysBySortOrder(report.fields[categoryName].sub_categories[subCategoryName].items)" :key="itemNameIndex">
                        <ViewFormItem
                          class="form-item"
                          lines="full"
                          :display_name="getLocalizedItemName(categoryName, itemName)"
                          :type="getLocalizedItem(report.fields[categoryName].sub_categories, subCategoryName, itemName, indexValue).type"
                          :value="getLocalizedItem(report.fields[categoryName].sub_categories, subCategoryName, itemName, indexValue).displayValue"
                          :unit="getLocalizedItem(report.fields[categoryName].sub_categories, subCategoryName, itemName, indexValue).unit"
                          :id="createUniqueItemID(report.id, categoryName, subCategoryName, itemName)" >
                        </ViewFormItem>
                      </template>
                    </template>
                  </div>
                </CollapsibleList>
              </ion-list>
            </div>
          </ion-card-content>
        </ion-card>

        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>

        <ion-fab :key="`${id}-${connection_id}`" v-if="areReportsLoaded" vertical="bottom" horizontal="end" slot="fixed">
          <ion-fab-button color="primary">
            <ion-icon :icon="ellipsisVertical"></ion-icon>
          </ion-fab-button>
          <ion-fab-list side="top">
            <ion-fab-button v-if="areReportsLoaded && !includesArchivedReport" :disabled="includesUploadingReport || isSingleConnectedReport || connectionIsUploading" color="success" @click="requestUpdate()"> <!-- Only show edit, if the report has not yet been edited! -->
              <ion-icon :icon="pencilOutline"></ion-icon>
            </ion-fab-button>
            <ion-fab-button color="danger" :disabled="includesUploadingReport || connectionIsUploading" @click="requestDelete()">
              <ion-icon :icon="trashOutline"></ion-icon>
            </ion-fab-button>
          </ion-fab-list>
        </ion-fab>
        <ion-fab :key="`${id}-${connection_id}`" vertical="bottom" horizontal="start" slot="fixed">
          <ion-fab-button color="tertiary" @click="shareReport()">
            <ion-icon :icon="shareSocialOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab>

        <ion-modal
          :is-open="validReportForRelationsSelected"
          @didDismiss="closeRelatedReportDialog()">
          <ion-page>
            <ion-header translucent>
              <ion-toolbar>
                <ion-title>
                  {{ i18n.$t('report.view.related-reports.title') }}
                </ion-title>
                <ion-buttons slot="end">
                  <ion-button @click="closeRelatedReportDialog()">{{ i18n.$t('default_interaction.close') }}</ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-header>

            <ion-content>
              <ion-list lines="full">
                <ion-item 
                  v-for="(relatedReport, index) in currentSelectedReportRelations" :key="index"
                  @click="(relatedReport.id != selectedReportIdForRelations) ? openRelatedReport(relatedReport.id) : null"
                  :button="(relatedReport.id != selectedReportIdForRelations)"
                  :detail="(relatedReport.id != selectedReportIdForRelations)"
                  :class="(relatedReport.id != selectedReportIdForRelations) ? '' : 'selected-related-report'">
                    <span slot="start" class="related-report-status">
                      <span class="status-icon">
                        <ion-icon v-if="isReportIdBeingUploaded(relatedReport.id)" :icon="cloudUploadOutline" color="warning" ></ion-icon>
                        <ion-icon v-else-if="index > 0" :icon="archiveOutline" color="secondary" ></ion-icon>
                        <ion-icon v-else :icon="checkmarkCircleOutline" color="success"></ion-icon>
                      </span>
                      <ion-label v-if="index == (currentSelectedReportRelations.length - 1)" color="secondary">{{ i18n.$t('report.view.related-reports.original') }}: </ion-label>
                      <ion-label v-else-if="index > 0" color="secondary">{{ i18n.$t('report.view.related-reports.version') }} {{ currentSelectedReportRelations.length - index - 1 }}: </ion-label>
                      <ion-label v-else :color="(isReportIdBeingUploaded(relatedReport.id)) ? 'warning' : 'success'">{{ i18n.$t('report.view.related-reports.latest') }}: </ion-label>
                    </span>
                    <span>{{ (isReportIdBeingUploaded(relatedReport.id)) ? i18n.$t('report.view.related-reports.uploading') : timeToString(relatedReport[METADATA_SHORT_KEY_MAPPING['created_at']]) }}</span>
                </ion-item>
              </ion-list>
            </ion-content>
          </ion-page>
        </ion-modal>
      </template>

      <ion-backdrop v-if="loading" visible="true" tappable="false"></ion-backdrop> <!-- TODO Test loading spinner behaviour on error -->
      <div class="loading-spinner" v-if="loading">
        <ion-spinner></ion-spinner>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonSpinner, IonList, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonItem, IonLabel, IonItemDivider, IonFab, IonFabList, IonFabButton, IonIcon, IonModal, IonToolbar, IonButtons, IonButton, IonTitle, IonBackdrop, alertController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, watch, /* onMounted */ } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { faCalendarDay, faHouseMedicalFlag, faClipboardList, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { pencilOutline, shareSocialOutline, documentTextOutline, ellipsisVertical, archiveOutline, checkmarkCircleOutline, trashOutline, cloudUploadOutline, chevronForward } from 'ionicons/icons';
import history from '@/assets/icons/history.svg';

import MainToolbar from '@/components/MainToolbar.vue';
import ViewFormItem from '@/components/forms/ViewFormItem.vue';
import ViewAnalysisItem from '@/components/forms/ViewAnalysisItem.vue';
import AnimalSelect from '@/components/AnimalSelect.vue';
import CollapsibleList from '@/components/CollapsibleList.vue';
import LongTabBar from '@/components/LongTabBar.vue';
import AnimatedLogo from '@/components/AnimatedLogo.vue';

import { useEditAnimalModal } from '@/components/composables/EditAnimalModal.js';

import CircularProgress from '@/components/CircularProgress.vue';

import _ from 'lodash';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

import { METADATA_SHORT_KEY_MAPPING, METADATA_PROPERTY, REPORT_PROPERTY_MAPPINGS, UNCATEGORIZED_CATEGORY, ADDITIONAL_COMMENT_FIELD, FIELDS_PROPERTY, parseKey, addKeyHierarchyAttributes, buildKey, typeDefinitionToHierarchicalObject, reportFieldsToHierarchicalObject, getSortedKeysBySortOrder, mergeHierarchicalObjectsWithoutExtend } from '@/utils/report';

import { apiErrorToast, localErrorToast } from '@/utils/error';

import { openReportShareModal, default as shareModalComponent } from '@/components/ReportShareModal.vue';
import { openUploadStatusModal, default as uploadModalComponent } from '@/components/UploadStatusModal.vue';

export default defineComponent({
  name: 'ViewReport',
  components: {
    IonPage, IonHeader, IonContent, IonSpinner, IonList, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonItem, IonLabel, IonItemDivider, IonFab, IonFabList, IonFabButton, IonIcon, IonModal, IonToolbar, IonButtons, IonButton, IonTitle, IonBackdrop, MainToolbar, ViewFormItem, CircularProgress, AnimalSelect, CollapsibleList, LongTabBar, AnimatedLogo, ViewAnalysisItem
  },
  props: {
    id: String,
    connection_id: String,
  },
  setup(props){
    const store = useStore();

    const i18n = useI18n();

    const router = useRouter();

    const editAnimalModal = useEditAnimalModal();

    const { dayjs } = useDayjs();

    const loading = ref(true);

    //Store properties and metadata as separate objects
    //Properties have to be equal for all reports
    const loadedReportProperties = ref({});
    //Metadata gets saved separatelyy
    const loadedReportMetadata = ref({});

    //Array of report objects with fields, type, id etc. set for each one. All fields that are unique for this report
    const loadedReportsData = ref([]);

    //Saves all report types that have been loaded
    const loadedReportTypes = ref({});

    const areReportsLoaded = computed(() => {
      return (loadedReportsData.value != null && Array.isArray(loadedReportsData.value) && loadedReportsData.value.length > 0);
    });

    const analysesStatusByReport = ref({});

    const analysesStatusUpdatePromise = ref(Promise.resolve());

    //Concurrency mechanism for loading to modify object without race conditions
    const reportUploadCompletionPromise = ref(Promise.resolve());

    const finishedUploads = ref({});
    
    watch([() => store.getters['reports/getFullReportMetadataById'], loadedReportsData, finishedUploads], ([reportMetadataGetter, newReportsData, newFinishedUploads]) => {
      //TODO Does it get updated when index refreshes with new analyses?
      analysesStatusUpdatePromise.value = analysesStatusUpdatePromise.value.then(async () => {
        let mappedReports = {};
        for (let reportData of newReportsData) {
          if (reportData.uploadStatus != null && reportData.uploadStatus.completionPromise != null) {
            reportUploadCompletionPromise.value = reportUploadCompletionPromise.value.then(() => reportData.uploadStatus.completionPromise).then((uploadedReport) => {
              if (!(reportData.id in finishedUploads.value)) finishedUploads.value = {...finishedUploads.value, [reportData.id]: uploadedReport.id}; //Add and reassign object to retrigger this watcher
            }).catch(() => null); //Catch to always resolve. Just a concurrency mechanism
          }
          
          let reportID = reportData.id;
          if (reportID != null) {
            //After an upload has finished use the real ID!
            if (reportID in newFinishedUploads && newFinishedUploads[reportID] != null) reportID = newFinishedUploads[reportID];
            let metadata = await reportMetadataGetter(reportID);
            if (metadata != null && metadata.analyses != null && Array.isArray(metadata.analyses)) {
              //Create an object by field_key and an array of analyses inside! Also counts each status value, if valid!
              let analysesObjectByFieldKey = _.reduce(metadata.analyses, function(result, analysis) {
                if (analysis.field_keys != null && Array.isArray(analysis.field_keys)) {
                  //Add statistics for this analysis
                  let completedState = false;
                  result.statistics['totalCount'] = (result.statistics['totalCount'] || 0) + 1;
                  if (analysis.status != null && analysis.status.completed) {
                    result.statistics['completed'] = (result.statistics['completed'] || 0) + 1;
                    completedState = true;
                  } else if (analysis.status != null && analysis.status.queued) {
                    result.statistics['uncompleted'] = (result.statistics['uncompleted'] || 0) + 1;
                    result.statistics['queued'] = (result.statistics['queued'] || 0) + 1;
                  } else {
                    result.statistics['uncompleted'] = (result.statistics['uncompleted'] || 0) + 1;
                    result.statistics['unqueued'] = (result.statistics['unqueued'] || 0) + 1;
                  }

                  //Parse category chain only if it is a single field that was analysed, to put it accordingly into the object
                  if (analysis.field_keys.length === 1) {
                    let key = analysis.field_keys[0];
                    let parsedKey = parseKey(key);
                    let analysesArray = _.get(result.analyses, key);
                    //If it doesn't exist yet for this path, create a new one and set it
                    if (analysesArray == null) {
                      analysesArray = [];
                      _.setWith(result.analyses, [key], analysesArray, Object)
                    }

                    let category = parsedKey[0];
                    //Should be false when at least one is uncompleted
                    if (!(category in result.categoryCompletedState)) result.categoryCompletedState[category] = completedState;
                    else result.categoryCompletedState[category] = result.categoryCompletedState[category] && completedState; //Only set to true when it already was true and the new one is also true

                    analysesArray.push(_.omit(analysis, ['field_keys']));
                  } else { //If it is a analysis with multiple field_keys it is a meta analysis, and will be added separately
                    result.metaAnalyses.push(analysis); //Do not omit used field_keys to potentially show them
                  }
                }

                return result;
              }, { analyses: {}, metaAnalyses: [], statistics: {}, categoryCompletedState: {}}); //Each category will have completedState either set to true or false if completed or uncompleted. Undefined if no analysis in that category.

              //Always set with old ID, while uploading it needs to be assigned to the uploading one!
              mappedReports[reportData.id] = analysesObjectByFieldKey;
            }
          }
        }

        analysesStatusByReport.value = mappedReports;
      }).catch(() => null); //Catch to always resolve. Just a concurrency mechanism
    });

    //Merge all the single statistics of each report together!
    const analysesStatistics = computed(() => {
      let mergedStatistics = {};

      _.forEach(_.map(analysesStatusByReport.value, 'statistics'), (statistics) => {
        for (let [key, value] of Object.entries(statistics)) {
          mergedStatistics[key] = (mergedStatistics[key] || 0) + value;
        }
      });

      return mergedStatistics;
    });

    const checkForMetadataUpdates = function() {
      store.dispatch('reports/fetchReportIndex');
    }

    const METADATA_UPDATE_INTERVAL_TIME_SECS = 10;
    const metadataUpdateInterval = ref(null);

    const startMetadataCheckInterval = function(){
      checkForMetadataUpdates();
      clearInterval(metadataUpdateInterval.value);
      metadataUpdateInterval.value = setInterval(checkForMetadataUpdates, METADATA_UPDATE_INTERVAL_TIME_SECS * 1000);
    }

    //While there are uncompleted analyses, start an interval with quicker timing than usual to check for results
    watch(analysesStatistics, (newStatistics) => {
      if (newStatistics != null && newStatistics.uncompleted > 0) {
        startMetadataCheckInterval();
      } else {
        clearInterval(metadataUpdateInterval.value);
        metadataUpdateInterval.value = null;
      }
    }, { immediate: true });

    const generateAnalysesStatisticsText = computed(() => {
      return function(statistics) {
        let statisticTexts = [];
        //Process without additional helper statistics
        for (let [statusKey, statisticValue] of Object.entries(_.omit(statistics, ['totalCount', 'uncompleted']))) {
          let localizedKey = i18n.$t(`tracking.analyses.${statusKey}`);
          statisticTexts.push(`${localizedKey}:\xA0${statisticValue}`);
        }

        return statisticTexts.join(', ');
      }
    })

    //Holds completed analyses that have been loaded one by one
    const loadedReportAnalyses = ref({});

    watch(analysesStatusByReport, (statusByReport) => {
      for (let [reportId, reportStatus] of Object.entries(statusByReport)) {
        if (reportStatus.analyses != null) {
          for (let analysesArray of _.concat(Object.values(reportStatus.analyses), [reportStatus.metaAnalyses])) {
            if (Array.isArray(analysesArray)) {
              for (let analysis of analysesArray) {
                if (analysis != null && analysis.id != null && analysis.status != null && !(analysis.id in loadedReportAnalyses.value) && analysis.status.completed) {
                  //After an upload has finished use the real ID!
                  if (reportId in finishedUploads.value && finishedUploads.value[reportId] != null) reportId = finishedUploads.value[reportId];
                  
                  store.dispatch('reports/getAnalysis', {id: reportId, analysis: analysis.id})
                  .then((result) => {
                    loadedReportAnalyses.value[analysis.id] = result;
                  })
                  .catch((error) => {
                    console.error(error);
                    loadedReportAnalyses.value[analysis.id] = null; //Null means, error when fetching
                  });
                }
              }
            }
          }
        }
      }
    });

    const mapAnalysesArrayWithLoadedAnalyses = computed(() => {
        let loadedAnalyses = loadedReportAnalyses.value;

        return function(analysisArray) {
          return _.map(analysisArray, (analysis) => {
              let mappedAnalysis = {
                ...analysis,
                item: _.get(loadedAnalyses, [analysis.id], undefined)
              }
              if (mappedAnalysis.item === null) _.set(mappedAnalysis, ['status', 'error'], true); //If null, not undefined it was an error
              return mappedAnalysis;
            });
        }
      });

    //Merge status and loaded items together
    const analysesStatusByReportWithLoadedItems = computed(() => {
      let analysesByReport = analysesStatusByReport.value;
      let mappingFunction = mapAnalysesArrayWithLoadedAnalyses.value;

      return _.mapValues(analysesByReport, (reportAnalyses) => {
        return {
          ...reportAnalyses,
          analyses: _.mapValues(reportAnalyses.analyses, (analysisArray) => mappingFunction(analysisArray)),
          metaAnalyses: mappingFunction(reportAnalyses.metaAnalyses)
        }
      });
    })

    const getAllAnalysesForReport = computed(() => {
      return function(reportId) {
        let reportAnalyses = _.get(analysesStatusByReportWithLoadedItems.value, [reportId], undefined);
        if (reportAnalyses == null) return undefined;

        let analysesArrays = (reportAnalyses['analyses'] != null) ? Object.values(reportAnalyses['analyses']) : [];
        return _.concat(...analysesArrays, (reportAnalyses['metaAnalyses'] || []));
      }
    });

    const getAnalysesOriginalData = function(fields, analysisItem) {
      let keyValues = {};

      if (fields != null && analysisItem != null && analysisItem.item != null && analysisItem.item.field_keys != null) {
        let fieldKeys = analysisItem.item.field_keys;

        for (let fieldKey of fieldKeys) {
          let keyHierarchy = addKeyHierarchyAttributes(parseKey(fieldKey));
          //Return item, if it is valid
          if (keyHierarchy != null) {
            //TODO Could be translated here, if not already translated before
            let item = _.get(fields, keyHierarchy);

            if (item != null) {
              //Take the first non-null value, starting with files
              let value = item.file;
              if (value == null) value = item.files;
              if (value == null) value = item.value;

              keyValues[fieldKey] = {
                type: item.type,
                unit: item.unit,
                value
              }
            }
          }
        }
      }
      
      return keyValues;
    };

    const getAnalysesInHierarchy = computed(() => {
      return function(reportId, categoryName, subCategoryName, itemName){
        //Check first if the report has any analyses
        let reportAnalyses = _.get(analysesStatusByReportWithLoadedItems.value, [reportId], undefined);
        if (reportAnalyses == null) return undefined;
        
        return _.get(reportAnalyses, ['analyses', buildKey(categoryName, subCategoryName, itemName)], undefined);
      }
    });

    //Merge for each report the fields and definition to include all fields, even those that are not in the definition. Definition can also be null!
    //Contains always all the fields. Adds metadata to the fields that are in the definition. If the categories and items are in the definition they get a sortOrder property from the definition.
    //If a key does not have a category, it is sorted into 'uncategorized'
    const loadedReportsFieldsAndTypes = computed(() => { //TODO Include localization here, maybe get the report from the API in different languages, when requested!
      return _.map(loadedReportsData.value, (reportData) => {
        let fields = _.get(reportData, FIELDS_PROPERTY);
        let type = _.get(reportData, 'type');
        let typeId;
        //If it is an object try to get ID and convert definition to be hierarchical, if it exists and it is valid
        if (_.isObject(type)) {
          typeId = _.get(type, ['id'], null);
          if (type.definition == null) {
            type.definition = typeDefinitionToHierarchicalObject(type.definition);
          }
        }
        //Otherwise just use the type itself, as it is a primitive type
        else {
          typeId = type;
          type = {};
        }

        //If the report type also has been loaded from the API, merge it into the existing ones to add more information
        let loadedType = _.get(loadedReportTypes.value, [typeId]);
        if (loadedType != null) {
          type = _.merge({}, type, loadedType);
        }

        //Transform fields to a hierarchical object in the same format as report definition
        if (fields != null) {
          [fields] = reportFieldsToHierarchicalObject(fields, true);

          //Get the type definition and merge with the types of the fields. More specific of type definition overwrites fieldTypes. Use empty object as fallback to always merge it correctly.
          let definition = _.get(type, 'definition');

          //If the report or the loaded type contains a valid definition, use that to set the definitions metadata to each field
          if (definition != null) {
            //Apply properties of the definition to the fields to add additional metadata and always use the type from the definition
            fields = mergeHierarchicalObjectsWithoutExtend((fields || {}), definition);
          }
        }
        
        //Return the report with the merged fields. And definition removed.
        return {
          ..._.omit(reportData, ['fields', 'type']),
          type: _.omit(type, 'definition'),
          fields: fields
        }
      });
    })

    //Contains all available animals and an additional empty entry for an animal that is not contained in the available ones, but loaded
    const availableHorses = computed(() => {
      //Make a copy of all available animals
      let animals = {...store.getters['horses/getHorseInfos']};
      //Get the preset horse (or from the properties) to check if it is included
      let presetHorseId = getPersonalInfoId.value(getReportProperty.value(REPORT_PROPERTY_MAPPINGS['animal'])); //FIXME Should be like in CreateReport, where it just displays no name, if it is not available. Same for tracking, no name and no horse are 2 different states!
      //If the preset one is not included in the available ones, add it as empty entry!
      if (!(presetHorseId in animals)) {
        animals[presetHorseId] = {};
      }
      return animals;
    });

    const getReportIdForRelations = function(report) {
        //Either takes the key of a current upload or the report ID (fallback) to look for relations
        return _.get(report, ['uploadStatus', 'key'], report['id']);
    }

    //Object Contains for each report the corresponding relations under its ID as the key
    const relatedReports = computed(() => {
      //Maps all the report keys to the related report lists, excludes all empty/invalid ones and creates an array
      return _.fromPairs(_.compact(_.map(loadedReportsData.value, (reportData) => {
        let uploadStatusKeyOrId = getReportIdForRelations(reportData);
        let relatedReports = store.getters['reports/getRelatedReportList'](uploadStatusKeyOrId);
        if (relatedReports == null) return null; //Invalid / empty, return null to exclude with compact
        //Return pairs of ID and relations to create object
        return [
          uploadStatusKeyOrId,
          relatedReports
        ];
      })));
    });

    const hasIdRelatedReports = computed(() => (id) => {
      return (id != null && relatedReports.value != null && id in relatedReports.value && 
              Array.isArray(relatedReports.value[id]) && relatedReports.value[id].length >= 1);
    });

    const hasRelatedReports = computed(() => (report) => {
      let uploadStatusKeyOrId = getReportIdForRelations(report);
      return hasIdRelatedReports.value(uploadStatusKeyOrId);
    });

    const isArchived = computed(() => (report) => {
      let uploadStatusKeyOrId = getReportIdForRelations(report);
      //Archived versions are those that are not on top of the chain, if the chain exists
      if (hasRelatedReports.value(report)){
        return (relatedReports.value[uploadStatusKeyOrId][0].id != uploadStatusKeyOrId);
      }

      return false;
    });

    //TODO Both includesArchivedReport and includesUploadingReport calculate the same thing. Too expensive for calculating twice? Lookups in store?
    const includesArchivedReport = computed(() => {
      //Check all the loaded reports if one of them is archived
      return _.some(loadedReportsData.value, (reportData) => {
        return isArchived.value(reportData);
      });
    });
    
    const includesUploadingReport = computed(() => {
      //Check all the loaded reports if one of them is currently being uploaded
      return _.some(loadedReportsData.value, (reportData) => {
        return isReportBeingUploaded.value(reportData);
      });
    });

    const isConnection = computed(() => {
      return (props.connection_id != null);
    });

    const uploadingConnectionStatus = computed(() => {
      let connectionID = props.connection_id;
      if (connectionID == null) return null;

      let connectionUploadIndex = store.getters['reports/getConnectionUploadStatus'];
      if (connectionUploadIndex == null) return null;
      
      return _.find(connectionUploadIndex, (statusObject) => statusObject.key == connectionID);
    });

    const connectionIsUploading = computed(() => {
      let status = uploadingConnectionStatus.value;
      //If it has an uploading status and it is not finished, return false so editing is prevented
      return (status != null && _.get(status, ['status', 'finishedID']) == null);
    });

    //Once upload is complete, forward the user to the finished report/connection
    watch([uploadingConnectionStatus, loadedReportsData, () => props.connection_id], ([connectionStatus, reportsData, connectionID]) => {
      let promises = [];
      let isConnection = false;

      //If it is a connection only wait until the connection has a status set and then wait for it to complete
      if (connectionID != null) {
        if (connectionStatus != null && connectionStatus.status != null && connectionStatus.status.completionPromise != null) {
          isConnection = true;
          promises.push(connectionStatus.status.completionPromise);
        }
      } else { //Otherwise wait for all reports (should just be one) to complete
        if (reportsData != null) {
          _.forEach(reportsData, (reportData) => {
            if (reportData != null && reportData.uploadStatus != null && reportData.uploadStatus.completionPromise != null) {
              promises.push(reportData.uploadStatus.completionPromise);
            }
          });
        }
      }

      //Only wait for completion if at least one is there to wait for
      if (promises.length > 0) {
        //Return the first finished one in the end, to update the ID
        Promise.all(promises).then((results) => {
          if (results != null && results.length > 0) {
            return _.get(results, [0, 'finishedID']); //TODO Take correct parameter
          } 
        }).then((finishedID) => {
          if (finishedID != null) {
            if (isConnection) {
              //FIXME The API needs to return the ID and the report index has to be refreshed until then with the new connections!
              //router.replace(`/health/analysis/reports/${finishedID}`);
            } else {
              //router.replace(`/health/analysis/report/${finishedID}`);
            }
          }
        });
      }
    }, { immediate: true });

    //Returns the connection ID array, if a single report is loaded that has at least one connection ID
    const getSingleReportConnection = computed(() => {
      if (props.id != null && Array.isArray(loadedReportsData.value) && loadedReportsData.value.length === 1) {
        let connections = loadedReportsData.value[0]['connections'];
        if (connections != null && Array.isArray(connections) && connections.length > 0) {
          return connections;
        }
      }
      return undefined;
    });

    //True, if it is a single report that is part of a connection
    const isSingleConnectedReport = computed(() => {
      return (getSingleReportConnection.value != null);
    });

    //Used to select the report relations that are shown in the dialog. If set and valid, the dialog is shown
    const selectedReportIdForRelations = ref(null);

    const currentSelectedReportRelations = computed(() => {
      //Only return the relations if they are valid
      if (hasIdRelatedReports.value(selectedReportIdForRelations.value)) {
        return relatedReports.value[selectedReportIdForRelations.value];
      }
      return null;
    });

    const validReportForRelationsSelected = computed(() => {
      return (currentSelectedReportRelations.value != null);
    });

    //Map reports to just include report ID as the key and the descriptor of the type in the correct order. Multiple of the same report types can be shown as an edge case.
    const availableReportsWithTypes = computed(() => {
      if (!Array.isArray(loadedReportsFieldsAndTypes.value)) return [];
      return _.map(loadedReportsFieldsAndTypes.value, (reportObject) => {
        let iconObject = {};

        if (isReportBeingUploaded.value(reportObject)) {
          let uploadStatus = getUploadStatusForReport.value(reportObject);
          if (uploadStatus != null) {
            iconObject = {
              icon: uploadStatus.design.icon,
              iconColor: uploadStatus.design.shortColor
            }
          }
        } else if (isArchived.value(reportObject)) {
          iconObject = {
            icon: history,
            iconColor: 'primary'
          }
        }

        return {
          key: reportObject.id,
          display: _.get(reportObject, 'type.descriptor', null), //TODO Can be localized here!
          ...iconObject
        }
      });
    });

    const currentVisibleReport = ref(null);

    //If available reports change, select the first report if it is valid
    watch(availableReportsWithTypes, (newReports) => {
      if (Array.isArray(newReports) && newReports.length > 0) {
        currentVisibleReport.value = newReports[0].key;
      }
    });

    const timeToString = computed(() => {
      return (timestamp) => {
        if (timestamp == null) return null;
        try {
          //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
          let localDateTime = dayjs((new Date(timestamp))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
          if (localDateTime != null && localDateTime.isValid()) {
            return localDateTime.format('LLL'); //TODO Test if local time format is still changed on language change! If not fix here, ViewReport, ViewFormItem and Tracking.
          }
        } catch {
          //Skip conversion to date on error!
          return null;
        }
      }
    });

    //Returns the property, if it is set, else fallbackDefault
    const getReportProperty = computed(() => (propertyName, fallbackDefault = null) => {
      return _.get(loadedReportProperties.value, [propertyName], fallbackDefault);
    });

    //Returns the metadata, if it is set, else fallbackDefault
    const getReportMetadata = computed(() => (propertyName, fallbackDefault = null) => {
      return _.get(loadedReportMetadata.value, [propertyName], fallbackDefault);
    });

    //Can translate category and sub category names
    const getLocalizedCategoryName = computed(() => (categoryName) => {
      return categoryName; //TODO Could be translated here, if not already translated before. Create a util function for translating!
    });

    //Returns a localized version of fixed item names. Always requires categoryName, never subCategoryName!
    const getLocalizedItemName = computed(() => (categoryName, itemName) => {
      //Use localized string for additional comment key
      if (itemName == ADDITIONAL_COMMENT_FIELD) {
        //Uncategorized means the whole report
        if (categoryName == UNCATEGORIZED_CATEGORY) {
          return i18n.$t('report.create.additional_comments_report');
        } else { //Otherwise just for this category
          return i18n.$t('report.create.additional_comments');
        }
      }
      return itemName; //TODO Could be translated here, if not already translated before
    });

    const getLocalizedItem = computed(() => (categories, categoryName, itemName, indexValue) => {
      //Return item, if it is valid
      if (categories != null && categoryName != null && itemName != null) {
        let keyHierarchy = [categoryName, 'items', itemName];
        if (indexValue != null) keyHierarchy = _.concat(keyHierarchy, ['indexed_values', indexValue]);
        //TODO Could be translated here, if not already translated before
        let item = _.get(categories, keyHierarchy, {}); //Return empty object as fallback to still access properties without errors

        //Take the first non-null value, starting with files
        let displayValue = item.file;
        if (displayValue == null) displayValue = item.files;
        if (displayValue == null) displayValue = item.value;

        return {
          type: item.type,
          unit: item.unit,
          displayValue
        }
      }
      
      return {}; //Return empty object as fallback
    });

    const getPersonalInfoId = computed(() => (horseId) => {
      //Map horse to personal info ID
      return store.getters['horses/getHorses'][horseId];
    });
    

    const fetchReport = function(id) {
      return store.dispatch('reports/fetchReport', id);
    }

    const processReportPromises = async function(reportPromiseArray) {
      //TODO Should the reports be sorted? By the type?
      return Promise.all(reportPromiseArray)
        .then((reportArray) => _.map(reportArray, (report) => { //First map it so it includes upload status and data at the same time!
          let reportObject = {...report.data, uploadStatus: report.uploadStatus};
          //Add id to uploading reports
          if (reportObject.uploadStatus != null && reportObject.id == null) reportObject.id = reportObject.uploadStatus.key;

          return reportObject;
        }))
        .then((reportArray) => {
          if (reportArray == null || reportArray.length < 1) throw new Error('No reports loaded!');

          let firstReport = _.first(reportArray);
          //Take properties and metadata from first report as they should be the same for all
          if (firstReport != null) {
            //Properties are the values defined in the mapping
            loadedReportProperties.value = _.pick(firstReport, Object.values(REPORT_PROPERTY_MAPPINGS));
            loadedReportMetadata.value = _.get(firstReport, METADATA_PROPERTY); ///TODO Get from the report in metadata or some other way. Will the property called differently?
          }

          //Map all reports to only include the left fields that are specific for each report
          loadedReportsData.value = _.map(reportArray, (report) => _.omit(report, _.concat([METADATA_PROPERTY], Object.values(REPORT_PROPERTY_MAPPINGS))));

          let reportTypeIDsToLoad = _.compact(_.map(reportArray, (report) => {
            let reportType = _.get(report, ['type'], null);
            //If it is an object try to get ID and convert definition to be hierarchical, if it exists and it is valid
            if (_.isObject(reportType)) return _.get(reportType, ['id'], null);
            //Otherwise just use the type itself, as it is a primitive type
            return reportType;
          }));

          //Load all in parallel and save the results in an array of promises
          let fetchReportTypePromises = _.map(reportTypeIDsToLoad, async (typeId) => {
            return store.dispatch('reports/fetchReportType', typeId);
          });

          //Wait until all are finished
          Promise.allSettled(fetchReportTypePromises)
          .then((reportTypeLoadStatus) => {
            //First add the reportIDs that were supposed to be loaded to the results
            _.zipWith(reportTypeLoadStatus, reportTypeIDsToLoad, (statusObject, typeId) => statusObject.id = typeId);
            //Filter out all successfully fetched types
            let fetchedTypes = _.filter(reportTypeLoadStatus, (statusObject) => statusObject.status === 'fulfilled');

            //Then convert it to an array of all loaded report types and the definition converted to a hierarchical object
            let convertedTypes = _.map(fetchedTypes, (statusObject) => {
              let fetchedType = statusObject.value;
              let definition = _.get(fetchedType, 'definition');

              //If the report type contains a valid definition convert it to hierarchical format for merging
              if (definition != null) {
                //Create a shallow copy with the definition being mapped
                fetchedType = {
                  id: statusObject.id,
                  ...fetchedType,
                  'definition': typeDefinitionToHierarchicalObject(definition)
                }
              }

              return fetchedType;
            });

            //Set the loaded types as the types we loaded from the API and converted here
            loadedReportTypes.value = _.keyBy(convertedTypes, 'id');
          });
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true) //TODO Test that it gets caught
            .then(() => router.go(-1));
        });
    }

    watch(() => props.id, (newId) => {
      if (newId != null) {
        loading.value = true;
        processReportPromises([fetchReport(newId)])
          .finally(() => loading.value = false);
      }
    }, { immediate: true });

    const connectionReportIDs = computed(() => store.getters['reports/getReportIDsInConnection']);

    watch([() => props.connection_id, connectionReportIDs], ([newConnectionId, newReportGetter]) => {
      if (newConnectionId != null) {
        loading.value = true;
        //Get all reports in this connection (updates automatically as the getter is reactive). //FIXME Reactivity does not work here
        let connections = newReportGetter(newConnectionId);
        //Fetch all reports by mapping each report id to the promise of fetchReport and give the result to the process function.
        processReportPromises(_.map(connections.reports, (reportId) => fetchReport(reportId)))
          .finally(() => loading.value = false);
      }
    }, { immediate: true });

    const requestUpdate = function(){
      if (isConnection.value) {
        router.push({ name: 'update-connected-reports', params: { connection_id: props.connection_id } });
      } else {
        router.push({ name: 'update-report', params: { id: props.id } });
      }
    }

    //If connection is defined, it must be an uploading one and then it is removed first
    const deleteReportsAndConnections = async function(reportIDs, connection = undefined, routeBack = true){
      //First delete connection from the upload queue, if it is specified - Use inline async function to create a promise in every case
      (async () => {
        if (connection != null) await store.dispatch('removeUpload', connection);
      })()
      .then(() => { //Then delete all reports at the same time, order does not matter, just make them all complete
        if (Array.isArray(reportIDs)) {
          return Promise.all(_.map(reportIDs, (id) => store.dispatch('reports/deleteReport', id)));
        }
      })
      .then(() => {
        toastController.create(
          {
            message: i18n.$t('report.view.deleted'),
            position: 'top',
            duration: 5000,
            color: 'success',
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        ).then((toast) => {
          toast.present();
          if (routeBack) router.replace(`/health/tracking`);
        });
      })
      .catch((error) => {
        apiErrorToast(i18n, error, true);
      });
    }

    const requestDelete = async function(){
      //TODO Remove this check and use the error from above when trying to delete once deletion of uploading entries is possible. Also remove check to show button again
      if (includesUploadingReport.value || connectionIsUploading.value) return localErrorToast(i18n, i18n.$t('report.view.errors.edit_uploading'));
      let currentReport = _.find(loadedReportsFieldsAndTypes.value, (reportObject) => reportObject.id == currentVisibleReport.value);
      if (currentReport == null) return;
      let currentReportIsArchived = isArchived.value(currentReport);
      let currentReportIsBeingUploaded = isReportBeingUploaded.value(currentReport);
      let currentReportHasRelatedReports = hasRelatedReports.value(currentReport);
      let reportTypeDescriptor = _.get(currentReport, 'type.descriptor', null); //TODO Can be localized here!
      let connectionID = props.connection_id;
      let allReports = _.map(loadedReportsFieldsAndTypes.value, (reportObject) => reportObject.id);
      //FIXME When deleting one that was just uploaded, the old version is deleted. And also the updates parameter still sticks around. Somewhow the normal deletion procedure is not followed! Also after deleting it still stays in the index, because the uploadQueue is still there!
      //FIXME Probably need to filter out all hidden ones from the updated relation too, maybe different check for deleted_at
      //FIXME Problem with the old being deleted is: When finishing it does not automatically load the one from the API. If there is an upload status set, take the key to delete.
      //FIXME How to treat uploaded ones in the index. Maybe should get replaced by the actual ones, not how it is now. Just thinking why they will be still visible in the Tracking even after beind deleted, as long as they are in the uploadQueue.

      const buttons = [
        {
          text: i18n.$t('default_interaction.cancel'),
          role: 'cancel'
        },
        {
          text: i18n.$t('report.view.delete-confirmation.delete' + ((connectionID != null) ? '-one' : '')),
          cssClass: 'delete-confirmation-okay',
          handler: () => {
            //Never remove the connection if it is just a single report being deleted
            deleteReportsAndConnections([currentReport.id], undefined, !(allReports != null && allReports.length > 1)); //Do not route back, if there will be at least one report left after the deletion!
          },
        }
      ];

      if (connectionID != null) {
        buttons.push({
          text: i18n.$t('report.view.delete-confirmation.delete-connected'),
          cssClass: 'delete-confirmation-okay',
          handler: () => {
            deleteReportsAndConnections(allReports, ((connectionIsUploading.value) ? connectionID : undefined));
          },
        });
      }

      const alert = await alertController
      .create({
        cssClass: 'delete-confirmation-alert',
        header: i18n.$t('report.view.delete-confirmation.title' + ((currentReportIsArchived) ? '-version' : (currentReportIsBeingUploaded ? '-upload' : '')) + ((connectionID != null) ? '-connected' : '')),
        message: i18n.$t('report.view.delete-confirmation.message.before' + ((currentReportIsArchived) ? '-version' : (currentReportIsBeingUploaded ? '-upload' : '')) + ((connectionID != null) ? '-connected' : '')) +
          ((reportTypeDescriptor != null) ? ('<b>(' + reportTypeDescriptor + ')</b>') : '') +
          i18n.$t('report.view.delete-confirmation.message.after') +
          ((connectionID != null) ? '<br/><br/>' + i18n.$t('report.view.delete-confirmation.message.after-connected') : '') +
          ((allReports != null && allReports.length > 1) ? ' <b>(' + allReports.length + ')</b>' : '') + 
          ((!currentReportIsBeingUploaded && currentReportHasRelatedReports) ? '<br/><br/>' + i18n.$t('report.view.delete-confirmation.message.delete-versions') : ''),

        buttons
      });
      return alert.present();
    }

    const shareReport = function(){
      //Create a mapped object of all report data for each ID
      let loadedReportsDataByIDs = _.keyBy(loadedReportsData.value, 'id');
      //Create an array in the correct order
      let loadedReportsIDs = _.map(loadedReportsData.value, 'id');
      openReportShareModal(shareModalComponent, loadedReportsIDs, loadedReportProperties.value, loadedReportMetadata.value, loadedReportsDataByIDs); //TODO Maybe date and datetime have to be converted
    }

    const showRelatedReportList = function(report){
      let uploadStatusKeyOrId = getReportIdForRelations(report);
      selectedReportIdForRelations.value = uploadStatusKeyOrId;
    }

    const closeRelatedReportDialog = function() {
      selectedReportIdForRelations.value = null;
    }

    const openRelatedReport = function(id){
      router.push({ name: 'view-report', params: { id: id } });
    }

    const openReportConnection = function(id){
      router.push({ name: 'view-connected-reports', params: { connection_id: id } });
    }

    const openAnimal = function(id, viewMode = true) {
      editAnimalModal.open(id, null, viewMode)
    }

    const getUploadStatusDesign = function(status){
      return store.getters['getUploadStatusDesign'](status);
    }

    //Get all statuses and map them into their displayed texts and design. Mapped to object with the keys being the upload status keys
    const reportUploadStatus = computed(() => _.keyBy(_.map((store.getters['reports/getUploadStatus'] || []), (statusObject) => {
      let originalStatus = (statusObject != null) ? statusObject.status : null;
      let mappedStatus = {
        ..._.pick(statusObject, ['key', 'index']),
        status: originalStatus,
        design: getUploadStatusDesign(originalStatus)
      };
      return mappedStatus;
    }), 'key'));

    //Returns a function to get the upload status for the given key, if it exists
    const getUploadStatus = computed(() => (key) => {
      if (reportUploadStatus.value != null && key != null && key in reportUploadStatus.value) {
        return reportUploadStatus.value[key];
      }
    });

    const getUploadStatusForReport = computed(() => (report) => {
      if (report != null && report.uploadStatus != null) {
        return getUploadStatus.value(report.uploadStatus.key);
      }
    });

    const isReportIdBeingUploaded = computed(() => (uploadStatusKeyOrId) => {
      let statusObject = getUploadStatus.value(uploadStatusKeyOrId);
      if (statusObject != null && _.get(statusObject, ['status', 'finishedID']) == null) return true;
      return false;
    });

    const isReportBeingUploaded = computed(() => (report) => {
      //If the report is invalid, it is not uploading
      if (report == null) return false;

      //If the status is set and not yet finished treat it as uploading
      if (report.uploadStatus != null && report.uploadStatus.key != null) {
        if (isReportIdBeingUploaded.value(report.uploadStatus.key)) return true;
      }

      return false;
    });

    const showUploadStatus = function(){
      openUploadStatusModal(uploadModalComponent);
    }

    const createUniqueItemID = function(...hierarchyParameters) { //Hierarchy is reportID, categoryName, subCategoryName (optional), itemName
      if (hierarchyParameters != null && hierarchyParameters.length > 1) { //TODO Index included?
        return `view-form-item-${_.join(hierarchyParameters, '__')}`;
      }
    }

    const scrollToItem = function(...hierarchyParameters) {
      //Use same order of parameters to get the unique ID to find the element where to scroll to
      let id = createUniqueItemID(...hierarchyParameters);
      if (id != null) {
        let element = document.getElementById(id);
        if (element != null) element.dispatchEvent(new CustomEvent('requestScrollToItem'));
      }
    }

    return {
      store,
      i18n,
      loading,
      isConnection,
      availableHorses,
      availableReportsWithTypes,
      areReportsLoaded,
      selectedReportIdForRelations,
      currentSelectedReportRelations,
      validReportForRelationsSelected,
      hasRelatedReports,
      isArchived,
      isReportIdBeingUploaded,
      isReportBeingUploaded,
      includesArchivedReport,
      includesUploadingReport,
      connectionIsUploading,
      getSingleReportConnection,
      isSingleConnectedReport,
      analysesStatusByReport,
      analysesStatistics,
      generateAnalysesStatisticsText,
      getAllAnalysesForReport,
      getAnalysesInHierarchy,
      getAnalysesOriginalData,
      loadedReportsFieldsAndTypes,
      currentVisibleReport,
      METADATA_SHORT_KEY_MAPPING,
      REPORT_PROPERTY_MAPPINGS,
      UNCATEGORIZED_CATEGORY,
      ADDITIONAL_COMMENT_FIELD,
      getSortedKeysBySortOrder,
      getReportProperty,
      getReportMetadata,
      getUploadStatusForReport,
      showUploadStatus,
      showRelatedReportList,
      closeRelatedReportDialog,
      openRelatedReport,
      openReportConnection,
      openAnimal,
      timeToString,
      getLocalizedCategoryName,
      getLocalizedItemName,
      getLocalizedItem,
      getPersonalInfoId,
      requestUpdate,
      requestDelete,
      shareReport,
      createUniqueItemID,
      scrollToItem,
      pencilOutline,
      shareSocialOutline,
      documentTextOutline,
      history,
      archiveOutline,
      checkmarkCircleOutline,
      ellipsisVertical,
      trashOutline,
      cloudUploadOutline,
      chevronForward,
      faCalendarDay,
      faHouseMedicalFlag,
      faClipboardList,
      faVoicemail
    }
  }
});
</script>

<style>
.delete-confirmation-okay, .overwrite-confirmation-okay {
  color: var(--ion-color-danger)!important;
}

.select-horse .new-button, .select-horse .new-button:hover {
  color: var(--ion-color-success-shade);
}
</style>

<style scoped>
.header-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

/*Query to check for big screen. Separate from js! */
@media (min-width: 700px) {
  .header-container {
    grid-template-columns: 5fr 6fr;
  }

  .ios .header-container {
    grid-template-columns: 1fr 1fr;
  }

  .header-container > *:not(:last-child) {
    margin-right: 0px;
  }
}

.header-card ion-card-content {
  padding: 0px;
}

.animal-select {
  display: flex;
  align-items: center;
  --padding-start: 10px;
  --custom-padding-start: 5px;
  --custom-avatar-icon-size: 1.2em;
  --custom-avatar-size: 46px;
}

.ios ion-card {
  margin-bottom: 0px;
  margin-top: 12px;
}

.ios ion-card-title {
  font-size: 1.3em;
}

ion-item, ion-list {
  --background: var(--ion-card-background, #fff);
}

/* Merge cards by setting no radius and no margin, so one card can be a sticky header, appearing like only part of the card is sticky */
.protocol-selection-header {
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.protocol-selection-overflow-bounds {
  overflow: hidden;

  position: sticky;
  top: 0px;
  z-index: 1000;
}

.protocol-selection-container {
  margin-top: 0px!important;
  margin-bottom: 0px;
  border-radius: 0px;

  border-bottom: 6px solid var(--ion-color-medium-light);
}

.protocol-selection {
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 0px;
  --width-offset: 16px;
  --background: var(--ion-card-background, #fff);
}

.ios .protocol-selection {
  --width-offset: 20px;
}

.protocol-content-container {
  margin-top: 0px!important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.protocol-content {
  padding: 0px;
}

.protocol-content-list {
  padding: 0px;
}

.protocol-hidden {
  display: none;
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.loading-spinner {
  z-index: 2500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--ion-color-light);
  padding: 15px;
  border-radius: 10px;
}

.ios .analyses-header {
  border-bottom-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250)));
}

.analyses-header {
  border-bottom: 3px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150)));
  font-size: 20px;
  --color: var(--ion-color-primary-text);
  color: var(--color);
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
  --custom-padding-start: 10px;
  --custom-item-margin: 10px;
}

.analyses-title {
  white-space: normal;
}

.category-header {
  font-size: 20px;
  --color: var(--ion-color-primary-text);
  color: var(--color);
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
  --custom-padding-start: 10px;
  --custom-item-margin: 10px;
}

ion-item-divider {
  --padding-start: 10px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --inner-padding-end: 10px;
}

.subcategory-divider {
  --color: var(--ion-color-dark);
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  font-size: 0.75em;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.form-item:not(.additional-comment-report) {
  --border-width: 0 0 1px 1px;
}

.analysis-item {
  margin-left: 15px;
}

.category-header:not(:first-child) {
  --collapsible-header-border-width: 1px 0px 1px 0px;
}

ion-item-divider ion-label {
  white-space: normal!important;
  text-overflow: unset;
  margin-top: 0px;
  margin-bottom: 0px;

  pointer-events: none;
  /* Disable select on texts */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.upload-status {
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px;
  --border-width: 0px 0px 4px 0px;
}

.upload-status ion-label {
  font-weight: bold;
  white-space: normal!important;
}

.connection-indicator {
  text-transform: none;
  --padding-top: 17px;
  --padding-bottom: 17px;
  --background: var(--ion-color-medium-light);
  --color: var(--ion-color-medium-light-contrast);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.connection-indicator ion-label {
  margin-left: 10px;
  margin-right: 10px;
  white-space: normal!important;
}

.connection-indicator ion-icon {
  vertical-align: bottom;
}

.version-history-button {
  --detail-icon-color: var(--ion-color-primary-text);
  --detail-icon-opacity: 0.7;
  --border-width: 0px 0px 4px 0px;
}

.version-history-button ion-label {
  font-weight: bold;
  white-space: normal!important;
}

.related-report-status {
  width: 110px;
}

.related-report-status .status-icon {
  height: 100%;
  vertical-align: middle;
  display: inline;
}

.related-report-status ion-icon {
  margin-right: 10px;
}

.related-report-status ion-label {
  display: inline;
}

.selected-related-report {
  font-weight: bold;
}

.upload-progress-button {
  height: 2.5em;
  width: 2.5em;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  font-size: inherit;
  background: var(--ion-item-background, var(--ion-background-color, #fff));
  border-radius: 50%;
}

ion-fab-button.fab-button-disabled {
  opacity: 0.5;
}

.logo {
  vertical-align: -0.2em;
  color: var(--ion-color-primary-text);
  font-size: 20px;
  margin-right: 5px;
}

.subcategory-index {
  padding-inline-start: 10px;
  color: var(--ion-color-primary-text);
}
</style>